.navigationbar {
  z-index: 999;
  height: $navbar-height;
  position: fixed;
  width: 100%;
  transition: background-color 500ms linear;
  .navbar {
    height: $navbar-height; }
  &__user {
    @include media-breakpoint-up(sm) {
      order: 3; }
    border-left: 1px solid $navbar-dark-color; }
  &--sticky {
    background-color: $dark; }
  @include media-breakpoint-down(xs) {
    .navbar-brand {
      margin-right: 0; }
    .navbar-toggler {
      padding: 0.25rem 0.25rem; } } }
.navplaceholder {
  height: 70px;
  width: 100%;
  background-color: $dark;
  position: fixed;
  top: 0;
  z-index: 990; }
.navplaceholder:after {
  position: relative;
  height: 70px; }
.recipedisplay {
  padding-top: 8rem; }

.navbar-dark .navbar-nav .dropdown-menu.show .nav-link {
  color: $body-color; }

.navbar-collapse {
  @include media-breakpoint-down(xs) {
    margin: 0.5rem -1rem;
    background-color: $white;
    padding: 1rem;
    .navbar-nav {
      .nav-link {
        color: rgba(0, 0, 0, 0.5);
        padding: 1.5rem 1rem; }
      .show > .nav-link, .active > .nav-link, .nav-link.show, .nav-link.active, .nav-link:focus {
        color: $dark; } } } }

.navbar-nav .dropdown-menu .nav-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }
