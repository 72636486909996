.item {
  &-hero {
    position: relative;
    overflow: hidden;
    background: rgba(0,0,0,.4);
    background-blend-mode: darken;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vH;
    @include media-breakpoint-down(md) {
      height: 15rem; }
    margin-bottom: 3rem;
    h1 {
      color: $white;
      margin-top: 4rem;
      @include media-breakpoint-down(md) {
        font-size: ($font-size-base * 3); }
      @include media-breakpoint-up(xl) {
        margin-top: 6rem; }
      @media only screen and (min-width: 1281px) {
        margin-top: 15rem; } } }
  &-subline {
    color: $white;
    font-size: 2rem;
    @include media-breakpoint-down(md) {
      font-size: ($font-size-base * 1.25); } }
  &-meta {
    text-align: right;
    margin-bottom: 5.5rem;
    @include media-breakpoint-down(md) {
      margin-bottom: 2rem; } }
  @media only screen and (min-width: 1281px) {
    font-size: ($font-size-base * 2); }
  h2 {
    @include media-breakpoint-down(md) {
      font-size: ($font-size-base * 2); } } }
