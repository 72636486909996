.home {
  &-hero {}

  &-refresh {
    margin-bottom: 1rem;
    @media only screen and (min-width: 1281px) {
      margin-top: 3.5rem; }
    i {
      float: left;
      margin-top: 5px; } }

  &__action {
    &__primary, &__secondary {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-weight: bold; }
    &__primary {
      height: 6rem; } } }
